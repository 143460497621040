import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Checkbox } from '@swyftx/aviary/atoms/Checkbox';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Modal } from '@swyftx/aviary/atoms/Modal';
import { Body, BodyLink } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';

type Props = {
  open: boolean;
  onBack: () => void;
  onContinue: () => void;
};

const WithdrawalAcknowledgementModal: React.FC<PropsWithChildren<Props>> = ({ open, onBack, onContinue, children }) => {
  const [accepted, setAccepted] = useState<boolean>(false);
  const isXs = useTailwindBreakpoint('xs');
  const { t } = useTranslation('wallet');

  useEffect(() => {
    if (!open) setAccepted(false);
  }, [open]);

  return (
    <Modal
      title={t('cryptoWithdrawalAcknowledgement.title')}
      triggerElement={children}
      open={open}
      position={isXs ? 'bottom' : 'center'}
      className='sm:max-w-[350px]'
      onClose={onBack}
    >
      <FlexLayout direction='column' spacing={24} className='px-24 pb-24'>
        <FlexLayout direction='column' spacing={8}>
          <Body size='small' color='secondary'>
            {t('cryptoWithdrawalAcknowledgement.subTitle')}
          </Body>
          <ul className='list-disc pl-8 text-10 text-color-text-secondary'>
            <li>
              <Body size='small' color='secondary'>
                {t('cryptoWithdrawalAcknowledgement.gambling')}
              </Body>
            </li>
            <li>
              <Body size='small' color='secondary'>
                {t('cryptoWithdrawalAcknowledgement.pharma')}
              </Body>
            </li>
            <li>
              <Body size='small' color='secondary'>
                {t('cryptoWithdrawalAcknowledgement.terms')}{' '}
                <BodyLink size='small' color='accent' target='_blank' className='cursor-pointer hover:underline'>
                  {t('cryptoWithdrawalAcknowledgement.termsLink')}
                </BodyLink>
              </Body>
            </li>
          </ul>
        </FlexLayout>

        <FlexLayout spacing={8}>
          <Checkbox checked={accepted} onCheckedChange={(checked) => setAccepted(!!checked)} />
          <Body size='small' className='cursor-pointer' onClick={() => setAccepted((prev) => !prev)}>
            {t('cryptoWithdrawalAcknowledgement.acceptTerms')}
          </Body>
        </FlexLayout>

        <FlexLayout direction='column' spacing={16}>
          <Button variant='filled' disabled={!accepted} onClick={onContinue} size='lg'>
            Continue
          </Button>
          <Button variant='outlined' onClick={onBack} size='lg'>
            Go back
          </Button>
        </FlexLayout>
      </FlexLayout>
    </Modal>
  );
};

export { WithdrawalAcknowledgementModal };
