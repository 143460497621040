export enum AppFeature {
  AssetList = 'asset-list',
  AutoInvest = 'auto-invest',
  AutoInvestFromBalances = 'auto-invest-from-balances',
  BankDepositDisabled = 'bank-deposit-disabled',
  Braze = 'braze',
  CostBasis = 'cost-basis',
  DemoMode = 'demo-mode',
  DoubleOTPForWebUpdate = 'double-otp-update-phone',
  ForceClassicLayoutVisible = 'force-classic-layout-visible',
  KoinlySso = 'koinly-sso',
  MaintenanceMode = 'maintenance-mode',
  OnboardingDepositPage = 'onboarding-deposit-page',
  OnboardingWizard = 'onboarding-wizard',
  PayIDDisabled = 'payid-disabled',
  PortfolioTransfer = 'portfolio-transfer',
  PortfolioBalanceReport = 'portfolio-balance-report',
  QuickBuy = 'quick-buy',
  ReferAFriend = 'refer-a-friend',
  ShowDeprecatedAddressWarning = 'show-deprecated-address-warning',
  ShowDeprecatedAddressWarningPart2 = 'show-deprecated-address-warning-part-2',
  ShowDeprecatedAddressWarningPart3 = 'show-deprecated-address-warning-part-3',
  ShowDeprecatedAddressWarningPart4 = 'show-deprecated-address-warning-part-4',
  ShowDeprecatedAddressWarningPart5 = 'show-deprecated-address-warning-part-5',
  SignUpForceMobileVerification = 'signup-require-mobile-verification',
  SourceOfWealth = 'source-of-wealth',
  Stripe = 'stripe',
  StripeAUOnly = 'deposit-card-stripe-au',
  TransactionReport = 'balance-sheet-tax-report',
  TriggerTrade = 'trigger-trades',
  UniversalTradeDefaultOpen = 'universal-trade-open-by-default',
  Wallets = 'wallets',
  UniversalTradePage = 'universal-trade-page',
  AviaryMarketTicker = 'aviary-market-ticker',
  ScrollingMarketTicker = 'scrolling-market-ticker',
  SwyftxPro = 'swyftx-pro',
  EntityOnboarding = 'entity-onboarding',
  MidnightTheme = 'midnight-theme',
  FireblocksDepositAddressAnnouncement = 'fireblocks-deposit-address-announcement',
  DynamicPriceAlerts = 'dynamic-price-alerts',
  DynamicPriceAlertsRelease2 = 'dynamic-price-alerts-release-2',
  Swaps = 'swaps',
  AssetDiscoveryV4SplitTest = 'asset-discovery-4-split-test',
  CryptoWithdrawalAcknowledgement = 'crypto-withdrawal-acknowledgement',
  ToursTutorialPage = 'tours-tutorial-page',
}

export enum AppFeatureData {
  BankDepositDisabledInformation = 'bank-deposit-disabled-information',
  PayIDDisabledInformation = 'payid-disabled-information',
  AssetRedenominationInformation = 'asset-redenomination-information',
}

/**
 * This is a custom JSON flag served from LaunchDarkly.
 * It specifies a `title` and `detail` strings, which can be used to
 * override the default text presented for any given deposit methods
 */
export interface DepositDisabledInformation {
  title: string;
  detail: string;
}

export interface AssetRedenominationInformation {
  dateTimeStart: number;
  dateTimeEnd: number;
  legacyAssetCode: string;
  link: string;
  rebrandedAsset: string;
}

const DEFAULT_ASSET_REDENOMINATION_INFORMATION: AssetRedenominationInformation[] = [];

const DEFAULT_DISABLED_INFORMATION: DepositDisabledInformation = {
  title: '',
  detail: '',
};

const AppFeatureLookup: Record<AppFeature, boolean> = {
  [AppFeature.AssetList]: true,
  [AppFeature.BankDepositDisabled]: false,
  [AppFeature.DemoMode]: true,
  [AppFeature.MaintenanceMode]: false,
  [AppFeature.OnboardingDepositPage]: true,
  [AppFeature.OnboardingWizard]: true,
  [AppFeature.PayIDDisabled]: false,
  [AppFeature.PortfolioTransfer]: true,
  [AppFeature.ReferAFriend]: true,
  [AppFeature.SignUpForceMobileVerification]: true,
  [AppFeature.StripeAUOnly]: true,
  [AppFeature.Stripe]: true,
  [AppFeature.TransactionReport]: true,
  [AppFeature.TriggerTrade]: true,
  [AppFeature.Wallets]: true,

  // Braze marketing testing features, should default to false
  [AppFeature.Braze]: false,

  // Focus week features, should default to false
  [AppFeature.CostBasis]: false,
  [AppFeature.ShowDeprecatedAddressWarning]: false,
  [AppFeature.ShowDeprecatedAddressWarningPart2]: false,
  [AppFeature.ShowDeprecatedAddressWarningPart3]: false,
  [AppFeature.ShowDeprecatedAddressWarningPart4]: false,
  [AppFeature.ShowDeprecatedAddressWarningPart5]: false,

  // We default values to false anyway but I think these flags could potentially change in the future
  // [AppFeature.Earn]: false, // As of 20/1/2023 Earn has been disabled
  // [AppFeature.FederatedLogin]: false, // As of 20/1/2023 This probably won't be enabled for a while as this was for SuperHero RIP

  // Koinly SSO, should default to false
  [AppFeature.KoinlySso]: false,

  // new Double OTP modal flow, currently for internal testing
  [AppFeature.DoubleOTPForWebUpdate]: false,

  // Source of Wealth, should default to false
  [AppFeature.SourceOfWealth]: true,

  [AppFeature.QuickBuy]: false,

  // Auto Invest should default to false
  [AppFeature.AutoInvest]: false,
  [AppFeature.AutoInvestFromBalances]: false,

  // Universal Trade open by default on login, should default to false
  [AppFeature.UniversalTradeDefaultOpen]: false,

  // Universal Trade page, should default to false
  [AppFeature.UniversalTradePage]: false,

  // Aviary Market Ticker, should default to false,
  [AppFeature.AviaryMarketTicker]: false,
  [AppFeature.ScrollingMarketTicker]: false,

  [AppFeature.PortfolioBalanceReport]: false,

  [AppFeature.ForceClassicLayoutVisible]: false,

  [AppFeature.SwyftxPro]: false,

  [AppFeature.EntityOnboarding]: false,
  [AppFeature.MidnightTheme]: false,
  [AppFeature.FireblocksDepositAddressAnnouncement]: false,
  [AppFeature.DynamicPriceAlerts]: false,
  [AppFeature.DynamicPriceAlertsRelease2]: false,
  [AppFeature.Swaps]: false,
  [AppFeature.AssetDiscoveryV4SplitTest]: false,
  [AppFeature.CryptoWithdrawalAcknowledgement]: false,

  [AppFeature.ToursTutorialPage]: false,
};

// this class allows us to create a composite lookup of AppFeatureData => type, and AppFeatureData => default value
// - we don't want to export this, as we can better export the benefits as specific helpers
class AppFeatureDataLookup implements Record<AppFeatureData, any> {
  private static singleton = new AppFeatureDataLookup();
  [AppFeatureData.BankDepositDisabledInformation]: DepositDisabledInformation = DEFAULT_DISABLED_INFORMATION;
  [AppFeatureData.PayIDDisabledInformation]: DepositDisabledInformation = DEFAULT_DISABLED_INFORMATION;
  [AppFeatureData.AssetRedenominationInformation]: AssetRedenominationInformation[] =
    DEFAULT_ASSET_REDENOMINATION_INFORMATION;

  static defaultValue<T extends AppFeatureData>(type: T): AppFeatureDataLookup[T] {
    return this.singleton[type];
  }
}

export type AppFeatureDataType<T extends AppFeatureData> = AppFeatureDataLookup[T];
export const getDefaultAppFeatureData = <T extends AppFeatureData>(feature: T): AppFeatureDataType<T> =>
  AppFeatureDataLookup.defaultValue(feature);

export const getDefaultAppFeature = (feature: AppFeature): boolean => AppFeatureLookup[feature] || false;
