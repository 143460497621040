import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Chip } from '@swyftx/aviary/atoms/Chip';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';
import { Cross, Locked, TickInCircle } from '@swyftx/aviary/icons/outlined';

import { UserStore } from '@shared/store';

import { ProfileVerificationContext } from '@Profile/subroutes/Verification/ProfileVerification.context';

import { observer } from 'mobx-react-lite';

import { VerificationFunctionalityItem } from './VerificationFunctionalityItem';
import { VerificationRequirementsItem } from './VerificationRequirementsItem';
import { VerificationLevel } from '../VerificationLevel.data';

type Props = {
  level: VerificationLevel;
  isRestricted: boolean;
  isRestrictedDisabled: boolean;
  backgroundColor: string;
};

const VerificationLevelCard: React.FC<Props> = observer(
  ({ level, isRestricted, isRestrictedDisabled, backgroundColor }) => {
    const { getAccountStatus } = UserStore.useUserStore;
    const { t } = useTranslation('profile.verification');
    const { resolveRestrictions } = useContext(ProfileVerificationContext);

    const isStatusSelected = useMemo(
      () => [level.status, level.restrictedStatus].indexOf(getAccountStatus()) > -1,
      [getAccountStatus, level.restrictedStatus, level.status],
    );
    const isLocked = () => Number(level.status) > Number(getAccountStatus());

    return (
      <FlexLayout
        direction='column'
        spacing={16}
        className={`flex min-h-[200px] rounded-12 p-24 @container ${backgroundColor}`}
      >
        <FlexLayout direction='column' spacing={8}>
          <FlexLayout direction='row' spacing={8}>
            {isStatusSelected && (
              <Chip color='primary' size='sm' leadingIcon={<TickInCircle className='text-color-text-inverse' />}>
                {t('verificationLevel.current')}
              </Chip>
            )}
            {!isStatusSelected && isLocked() && (
              <Chip size='sm' color='secondary' leadingIcon={<Locked className='h-12 w-12' />}>
                {t('verificationLevel.locked')}
              </Chip>
            )}
            {isStatusSelected && isRestricted && (
              <Chip leadingIcon={<Cross className='h-16 w-16' />} color='destructive' size='sm' variant='subtle'>
                {t('verificationLevel.accountRestricted')}
              </Chip>
            )}
          </FlexLayout>
          <Heading size='h3'>{t(level.label as any)}</Heading>
        </FlexLayout>

        <FlexLayout direction='row' className='flex-wrap'>
          {level.functionalityUnlocked.length && (
            <FlexLayout direction='column' className='my-4 w-full @sm:w-1/2 @md:w-1/3' spacing={4}>
              <Body size='small' weight='emphasis'>
                Feature unlocked
              </Body>
              <FlexLayout direction='column' spacing={8}>
                {level.functionalityUnlocked.map((functionality) => (
                  <VerificationFunctionalityItem key={functionality} functionality={functionality} />
                ))}
              </FlexLayout>
            </FlexLayout>
          )}

          {level.requirementsToUnlock.length > 0 && (
            <FlexLayout direction='column' className='my-4 w-full @sm:w-1/2 @md:w-1/3' spacing={4}>
              <Body size='small' weight='emphasis'>
                {!isLocked ? "What you've completed" : 'How to unlock'}
              </Body>
              <FlexLayout direction='column' spacing={8}>
                {level.requirementsToUnlock.map((requirement) => (
                  <VerificationRequirementsItem key={requirement} requirement={requirement} />
                ))}
              </FlexLayout>
            </FlexLayout>
          )}
        </FlexLayout>

        {isRestricted && (
          <Button size='lg' onClick={resolveRestrictions} disabled={isRestrictedDisabled}>
            {t('verificationLevel.buttonLabels.resolve')}
          </Button>
        )}
      </FlexLayout>
    );
  },
);

export { VerificationLevelCard };
